interface Props {
  children: React.ReactNode;
}

export function ButtonListContainer({ children }: Props) {
  return (
    <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
      {children}
    </div>
  );
}
